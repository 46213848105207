import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { TranslatorService } from '../services/translator.service';
import { TranslatePipe } from './pipes';
import pkg from '../../../package.json';
import { ConfigService } from '../services/config.service';
import { ActivatedRoute } from '@angular/router';

export const ApiInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const toastrService = inject(ToastrService);
    const authenticationService = inject(AuthenticationService);
    const translatorService = inject(TranslatorService);
    const translatePipe = inject(TranslatePipe);
    const configService = inject(ConfigService);
    const activatedRoute = inject(ActivatedRoute);
    const version = `${pkg.version}-${translatorService.getCurrentLanguage()}`;

    let clonedRequest = request.clone({
        headers: request.headers.append('Accept-Language', translatorService.getCurrentLanguage())
    });

    const token = configService.getConfig().token;
    if (token) {
        clonedRequest = clonedRequest.clone({
            headers: clonedRequest.headers.append('captcha-token', token)
        });
        configService.setConfig({ token: null });
    }

    const token2 = configService.getConfig().token2;
    if (token2) {
        clonedRequest = clonedRequest.clone({
            headers: clonedRequest.headers.append('captcha-token-v2', token2)
        });
        configService.setConfig({ token2: null });
    }

    if (clonedRequest.method == 'GET') {
        clonedRequest = clonedRequest.clone({
            setParams: {
                v: version
            }
        });
    }
    return next(clonedRequest).pipe(
        catchError((error) => {
            if (checkNoNetworkConnection(error)) {
                toastrService.error('No network connection. Please try again later.');
                const error = new Error('No network connection');
                console.error(error);
            }
            switch (error.status) {
                case 400:
                    break;
                case 401:
                    authenticationService.onUnauth();
                    break;
                case 500:
                    failwhale(error);
                    break;
                case 403:
                    failwhale(error);
                    break;
                case 404:
                    failwhale(error);
                    break;
            }
            return throwError(() => error);
        })
    );
};

const failwhale = (error: HttpErrorResponse) => {
    const msg = `${error.error}`;
    if (document.getElementsByClassName('page') && document.getElementsByClassName('page').length) {
        document.getElementsByClassName('page')[0].innerHTML = msg;
    } else {
        document.getElementsByTagName('BODY')[0].innerHTML = msg;
    }
    // modals
    if (document.getElementsByClassName('modal-backdrop') && document.getElementsByClassName('modal-backdrop').length) {
        document.getElementsByClassName('modal-backdrop')[0].setAttribute('style', 'display:none !important');
    }
    if (document.getElementsByClassName('modal') && document.getElementsByClassName('modal').length) {
        document.getElementsByClassName('modal')[0].setAttribute('style', 'display:none !important');
    }
    if (document.getElementsByClassName('modal-open') && document.getElementsByClassName('modal-open').length) {
        document.getElementsByClassName('modal-open')[0].setAttribute('style', 'overflow:unset !important');
    }
    // footer
    if (document.getElementsByClassName('footer') && document.getElementsByClassName('footer').length) {
        document.getElementsByClassName('footer')[0].setAttribute('style', 'display:none !important');
    }
};

const checkNoNetworkConnection = (error: any): boolean => {
    return (
        error instanceof HttpErrorResponse &&
        !error.headers.keys().length &&
        !error.ok &&
        !error.status &&
        !error.error.loaded &&
        !error.error.total
    );
};
